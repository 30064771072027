import { ref } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useMenteeMessages = () => {
  const menteeMessages = ref([])
  const getMenteeMessages = async (mentee) => {
    await httpClient.fetchMenteeMessages(mentee)
      .then(response => {
        if(response.data.result) {
          menteeMessages.value = response.data.result
        }
      })
      .catch(error => {

      })
  }

  return {
    menteeMessages,
    getMenteeMessages
  }
}
