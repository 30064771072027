import { ref, watch, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useMentorFullTextSearch = () => {
  const searchValue = ref(null)
  const searchResults = ref(null)

  watch(searchValue, (value) => {
    if(value.length >= 3) {
      const payload = {
        search_value: value
      }
      httpClient.searchMentors(payload)
        .then(response => {
          searchResults.value = response.data
        })
        .catch(error => {})
    }
    else {
      searchResults.value = null
    }
  })

  const searchedMentors = computed(({ filteredMentors }) => {
    let searchedMentors = filteredMentors
    let searchedMentorIds = searchedMentors.map(mentor => mentor.id)
    if(searchResults.value) {
      searchedMentors = searchResults.value.filter(mentor => searchedMentorIds.includes(mentor.id))
    }
    return searchedMentors
  })

  return {
    searchValue,
    searchedMentors
  }
}
