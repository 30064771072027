import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import store from '@/store'

export const usePendingEditRequest = () => {
  const pendingEditRequests = ref([])

  const getPendingEditRequests = async () => {
    await store.dispatch('mentor/getPendingEditRequestsList')
    pendingEditRequests.value = store.getters['mentor/getPendingEditRequestsList']
  }

  const lastRequestOfMentors = computed(() => {
    if(pendingEditRequests.value) {
      pendingEditRequests.value.sort((a, b) => a.request_date >= b.request_date ? -1 : 1)
      const mentorIds = pendingEditRequests.value.map(pendingEditRequest => pendingEditRequest.mentor.id)
      return pendingEditRequests.value.filter((pendingEditRequest, index) => mentorIds.indexOf(pendingEditRequest.mentor.id) === index)
    }
    return []
  })

  const getPendingEditRequestsOfMentor = async (mentorId) => {
    const allPendingEditRequests = store.getters['mentor/getPendingEditRequestsList']
    if(allPendingEditRequests.length <= 0) {
      refreshPendingEditRequestsOfMentor(mentorId)
    }
    else{
      pendingEditRequests.value = allPendingEditRequests.filter((pendingEditRequest) => pendingEditRequest.mentor.id == mentorId)
    }
  }

  const refreshPendingEditRequestsOfMentor = async (mentorId) => {
    const payload = {
      mentor_id: mentorId
    }
    await httpClient.fetchPendingEditRequestsOfMentor(payload)
      .then(response => {
        pendingEditRequests.value = response.data
      })
      .catch(error => {
  
      })
  }

  return {
    pendingEditRequests,
    getPendingEditRequests,
    lastRequestOfMentors,
    getPendingEditRequestsOfMentor,
    refreshPendingEditRequestsOfMentor
  }
}
