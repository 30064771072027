import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()

export const useCountyRepository = () => {
  const counties = ref([])
  const getCounties = async () => {
    const resp = await httpClient.fetchCounties()
    counties.value = resp.data
  }
  const countyOptions = computed(() => {
    let options = [{ value: null, text: t('Choose county') }]
    counties.value.map((county) => { options.push({value: county.id, text: t(county.name) }) })
    return options
  })
  
  return {
    counties,
    getCounties,
    countyOptions
  }
}
