import { ref } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useTestimonial = () => {
  const testimonials = ref([])

  const getTestimonials = async (mentorId) => {
    const payload = {
      mentor_id: mentorId
    }
    await httpClient.fetchTestimonials(payload)
      .then(response => {
        testimonials.value = response.data
      })
      .catch(error => {})
  }

  const getMyTestimonials = async (mentorId) => {
    const payload = {
      mentor_id: mentorId
    }
    await httpClient.fetchMyTestimonials(payload)
      .then(response => {
        testimonials.value = response.data
      })
      .catch(error => {})
  }

  const createTestimonial = (payload) => {
    return httpClient.createTestimonial(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  const editTestimonial = (payload) => {
    return httpClient.editTestimonial(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  const deleteTestimonial = (payload) => {
    return httpClient.deleteTestimonial(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  return {
    testimonials,
    getTestimonials,
    getMyTestimonials,
    createTestimonial,
    editTestimonial,
    deleteTestimonial
  }
}
