import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { packageStatuses } from '@mentoring-platform/constants'

const { t } = useI18nUtils()

export const useUserPackagesRepository = () => {
  const userSubscriptions = ref([])
  const getUserSubscriptions = async (mentee) => {
    await httpClient.fetchMenteeSubscriptions(mentee)
      .then(response => {
        if(response.data.result) {
          userSubscriptions.value = response.data.result
        }
      })
      .catch(() => {})
  }

  const userPackages = ref([])
  const getUserPackages = async () => {
    await httpClient.fetchMenteeSubscriptionPackages()
      .then(resp => userPackages.value = resp.data)
      .catch(() => {})
  }

  const userMentoringPackages = computed(() => {
    return userPackages.value.filter(userPackage => !userPackage.is_coaching)
  })

  const userCoachingPackages = computed(() => {
    return userPackages.value.filter(userPackage => userPackage.is_coaching)
  })

  const userAwaitingPaymentMentoringPackages = computed(() => {
    return userMentoringPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_AWAITING_PAYMENT)
  })

  const userPaidMentoringPackages = computed(() => {
    return userMentoringPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_PAID)
  })

  const userPendingMentoringPackages = computed(() => {
    return userMentoringPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_PENDING)
  })

  const userRunningMentoringPackages = computed(() => {
    return userMentoringPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_RUNNING)
  })

  const userCompletedMentoringPackages = computed(() => {
    return userMentoringPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_COMPLETED)
  })

  const userAwaitingPaymentCoachingPackages = computed(() => {
    return userCoachingPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_AWAITING_PAYMENT)
  })

  const userPaidCoachingPackages = computed(() => {
    return userCoachingPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_PAID)
  })

  const userPendingCoachingPackages = computed(() => {
    return userCoachingPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_PENDING)
  })

  const userRunningCoachingPackages = computed(() => {
    return userCoachingPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_RUNNING)
  })

  const userCompletedCoachingPackages = computed(() => {
    return userCoachingPackages.value.filter(userPackage => userPackage.status == packageStatuses.PACKAGE_STATUS_COMPLETED)
  })

  const packageStatusToText = (status) => {
    if(status == 1) {
      return t('Awaiting payment')
    }
    else if(status == 2) {
      return t('Paid')
    }
    else if(status == 3) {
      return t('Pending')
    }
    else if(status == 4) {
      return t('Running')
    }
    else if(status == 5) {
      return t('Completed')
    }
  }

  const packageStatusToColorVariant = (status) => {
    if(status == 1) {
      return 'light-warning'
    }
    else if(status == 2) {
      return 'light-success'
    }
    else if(status == 3) {
      return 'light-primary'
    }
    else if(status == 4) {
      return 'light-info'
    }
    else if(status == 5) {
      return 'light-dark'
    }
  }

  return {
    userSubscriptions,
    getUserSubscriptions,
    userPackages,
    userAwaitingPaymentMentoringPackages,
    userPaidMentoringPackages,
    userPendingMentoringPackages,
    userRunningMentoringPackages,
    userCompletedMentoringPackages,
    userAwaitingPaymentCoachingPackages,
    userPaidCoachingPackages,
    userPendingCoachingPackages,
    userRunningCoachingPackages,
    userCompletedCoachingPackages,
    getUserPackages,
    packageStatusToText,
    packageStatusToColorVariant
  }
}
