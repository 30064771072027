import { ref } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useSubscription = () => {
  const markSubscriptionAsPaid = (subscription) => {
    const payload = {
      id: subscription.subscription_id
    }
    return httpClient.markSubscriptionAsPaid(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  return {
    markSubscriptionAsPaid
  }
}
