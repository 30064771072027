import { ref } from '@vue/composition-api'

export const useLocales = () => {
  /* eslint-disable global-require */
  const locales = ref([{
    locale: 'gr',
    img: require('@/assets/images/flags/gr.svg'),
    name: 'ΕΛ',
  },
  {
    locale: 'en',
    img: require('@/assets/images/flags/gb.svg'),
    name: 'EN',
  }])
  /* eslint-disable global-require */

  const getSpecificLocale = localeCode => {
    if (localeCode) {
      return locales.value.find(localeInfo => localeInfo.locale === localeCode)
    }
    return null
  }

  return {
    locales,
    getSpecificLocale
  }
}
