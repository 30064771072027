import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { mentorshipRequestStatuses } from '@mentoring-platform/constants'

export const useMenteeMentorships = () => {
  const mentorshipRequests = ref([])
  const getMentorshipRequests = async (mentee) => {
    await httpClient.fetchMentorshipRequestsOfMentee(mentee)
      .then(response => {
        if(response.data.result) {
          mentorshipRequests.value = response.data.result
        }
      })
      .catch(error => {

      })
  }
  const pendingMentorshipRequests = computed(() => {
    return mentorshipRequests.value.filter(mentorshipRequest => mentorshipRequest.status == mentorshipRequestStatuses.MENTORSHIP_REQUEST_STATUS_PENDING)
  })

  return {
    mentorshipRequests,
    getMentorshipRequests,
    pendingMentorshipRequests
  }
}