import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useCurrentMentorshipRepository = () => {
  const currentMentorship = ref(null)
  const getCurrentMentorship = async () => {
    await httpClient.fetchCurrentMentorship()
      .then(response => {
        if(response.data.result) {
          currentMentorship.value = response.data.result
        }
        else {
          currentMentorship.value = null
        }
      })
      .catch(error => {

      })
  }
  const getCurrentMentorshipForMentor = async (mentor) => {
    await httpClient.fetchCurrentMentorshipForMentor(mentor)
      .then(response => {
        if(response.data.result) {
          currentMentorship.value = response.data.result
        }
        else {
          currentMentorship.value = null
        }
      })
      .catch(error => {

      })
  }
  const getCurrentMentorshipForMentee = async (mentee) => {
    await httpClient.fetchCurrentMentorshipForMentee(mentee)
      .then(response => {
        if(response.data.result) {
          currentMentorship.value = response.data.result
        }
        else {
          currentMentorship.value = null
        }
      })
      .catch(error => {

      })
  }
  const increaseCompletedSessions = async (mentee) => {
    return httpClient.increaseCompletedSessions(mentee)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }
  const decreaseCompletedSessions = async (mentee) => {
    return httpClient.decreaseCompletedSessions(mentee)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  return {
    currentMentorship,
    getCurrentMentorshipForMentor,
    getCurrentMentorshipForMentee,
    getCurrentMentorship,
    increaseCompletedSessions,
    decreaseCompletedSessions
  }
}