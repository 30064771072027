import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()

export const useWorkStatusRepository = () => {
  const workStatuses = ref([])
  const getWorkStatuses = async () => {
    const resp = await httpClient.fetchWorkStatuses()
    workStatuses.value = resp.data
  }
  const workStatusOptions = computed(() => {
    let options = [{ value: null, text: t('Choose') }]
    workStatuses.value.forEach((status) => { options.push({value: status.id, text: t(status.description) }) })
    options.push({ value: 'other', text: t('Other') })
    return options
  })

  return {
    workStatuses,
    getWorkStatuses,
    workStatusOptions
  }
}
