import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { mentorshipRequestStatuses } from '@mentoring-platform/constants'

export const useMentorMentorships = () => {
  const mentorshipRequests = ref([])
  const getMentorshipRequests = async (mentor) => {
    await httpClient.fetchMentorshipRequestsOfMentor(mentor)
      .then(response => {
        if(response.data.result) {
          mentorshipRequests.value = response.data.result
        }
      })
      .catch(error => {

      })
  }
  const approveMentorshipRequest = async (request) => {
    const payload = {
      id: request.id,
      mentor_id: request.mentor.id,
      status: mentorshipRequestStatuses.MENTORSHIP_REQUEST_STATUS_APPROVED
    }
    await httpClient.handleMentorshipRequestStatus(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }
  const rejectMentorshipRequest = async (request, reject_reason) => {
    const payload = {
      id: request.id,
      mentor_id: request.mentor.id,
      status: mentorshipRequestStatuses.MENTORSHIP_REQUEST_STATUS_REJECTED,
      reject_reason: reject_reason
    }
    await httpClient.handleMentorshipRequestStatus(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }
  const pendingMentorshipRequests = computed(() => {
    return mentorshipRequests.value.filter(mentorshipRequest => mentorshipRequest.status == mentorshipRequestStatuses.MENTORSHIP_REQUEST_STATUS_PENDING)
  })
  const rejectedMentorshipRequests = computed(() => {
    return mentorshipRequests.value.filter(mentorshipRequest => mentorshipRequest.status == mentorshipRequestStatuses.MENTORSHIP_REQUEST_STATUS_REJECTED)
  })
  const previousMentorships = ref([])
  const getPreviousMentorships = async (mentor) => {
    await httpClient.fetchPreviousMentorshipsOfMentor(mentor)
      .then(response => {
        if(response.data.result) {
          previousMentorships.value = response.data.result
        }
      })
      .catch(error => {
        
      })
  }

  return {
    mentorshipRequests,
    getMentorshipRequests,
    approveMentorshipRequest,
    rejectMentorshipRequest,
    pendingMentorshipRequests,
    rejectedMentorshipRequests,
    previousMentorships,
    getPreviousMentorships
  }
}