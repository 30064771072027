import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export const useMenteeRepository = () => {
  const mentees = ref([])
  const getMentees = async () => {
    mentees.value = store.getters['mentee/getList']
    if(mentees.value.length <= 0) {
      await store.dispatch('mentee/getList')
      mentees.value = store.getters['mentee/getList']
    }
  }
  const refreshMentees = async () => {
    await store.dispatch('mentee/getList')
    mentees.value = store.getters['mentee/getList']
  }
  const getSpecificMentee = async (id) => {
    await getMentees()
    return mentees.value.find(mentee => mentee.id == id)
  }

  return {
    mentees,
    getMentees,
    refreshMentees,
    getSpecificMentee
  }
}
