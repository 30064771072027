import { ref } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { mentorshipRequestStatuses } from '@mentoring-platform/constants'

export const useMentorshipRequest = () => {
  const mentorshipRequests = ref([])
  const getPendingMentorshipRequests = async () => {
    await httpClient.fetchMentorshipRequests({status: mentorshipRequestStatuses.MENTORSHIP_REQUEST_STATUS_PENDING})
      .then(response => {
        if(response.data.result) {
          mentorshipRequests.value = response.data.result
        }
      })
      .catch(error => {

      })
  }
  const getRejectedMentorshipRequests = async () => {
    await httpClient.fetchMentorshipRequests({status: mentorshipRequestStatuses.MENTORSHIP_REQUEST_STATUS_REJECTED})
      .then(response => {
        if(response.data.result) {
          mentorshipRequests.value = response.data.result
        }
      })
      .catch(error => {

      })
  }

  return {
    mentorshipRequests,
    getPendingMentorshipRequests,
    getRejectedMentorshipRequests
  }
}