import httpClient from '@mentoring-platform/http/httpClient'

export const useEmailCheck = () => {
  const canBeUsed = (email) => {
    return httpClient.checkEmail(email)
      .then(response => {
        if(response.data.message === 'OK') {
          return true
        }
      })
      .catch(error => false)
  }
  
  return {
    canBeUsed
  }
}
