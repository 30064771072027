import i18n from '@/libs/i18n'

export const useMentorHelpers = () => {
  const getMentorVisibleFullName = mentor => {
    const currentLocale = i18n.locale
    if (mentor.translatable_fields.name[currentLocale] && mentor.translatable_fields.surname[currentLocale]) {
      return `${mentor.translatable_fields.name[currentLocale]} ${mentor.translatable_fields.surname[currentLocale]}`
    }
    const selectedLocale = mentor.supported_languages[0]
    return `${mentor.translatable_fields.name[selectedLocale]} ${mentor.translatable_fields.surname[selectedLocale]}`
  }

  const getMentorVisibleProfessionalTitle = mentor => {
    const currentLocale = i18n.locale
    if (mentor.translatable_fields.professional_title[currentLocale]) {
      return mentor.translatable_fields.professional_title[currentLocale]
    }
    const selectedLocale = mentor.supported_languages[0]
    return mentor.translatable_fields.professional_title[selectedLocale]
  }

  const getMentorVisibleDescription = mentor => {
    const currentLocale = i18n.locale
    if (mentor.translatable_fields.description[currentLocale]) {
      return mentor.translatable_fields.description[currentLocale]
    }
    const selectedLocale = mentor.supported_languages[0]
    return mentor.translatable_fields.description[selectedLocale]
  }

  return {
    getMentorVisibleFullName,
    getMentorVisibleProfessionalTitle,
    getMentorVisibleDescription
  }
}
