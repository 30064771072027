import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useScholarship = () => {
  const scholarships = ref([])

  const getScholarships = async () => {
    await httpClient.fetchScholarships()
      .then(response => {
        if(response.data.result) {
          scholarships.value = response.data.result
        }
      })
      .catch(error => {})
  }

  const getScholarshipsOfMentee = async (mentee) => {
    await httpClient.fetchScholarshipsOfMentee(mentee)
      .then(response => {
        if(response.data.result) {
          scholarships.value = response.data.result
        }
      })
      .catch(error => {})
  }

  const scholarshipOptions = computed(() => {
    let options = []
    scholarships.value.forEach((scholarship) => { options.push({value: scholarship.id, text: scholarship.name }) })
    return options
  })

  const createScholarship = (payload) => {
    return httpClient.createScholarship(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  const editScholarship = (payload) => {
    return httpClient.editScholarship(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  const giveScholarshipToMentee = (payload) => {
    return httpClient.giveScholarshipToMentee(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  const removeScholarshipFromMentee = payload => {
    return httpClient.removeScholarshipFromMentee(payload)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
  }

  return {
    scholarships,
    scholarshipOptions,
    getScholarships,
    createScholarship,
    editScholarship,
    getScholarshipsOfMentee,
    giveScholarshipToMentee,
    removeScholarshipFromMentee
  }
}
