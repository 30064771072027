import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()

export const useUserReferralRepository = () => {
  const userReferrals = ref([])
  const getUserReferrals = async () => {
    const resp = await httpClient.fetchUserReferrals()
    userReferrals.value = resp.data
  }
  const userReferralOptions = computed(() => {
    let options = []
    userReferrals.value.forEach((referral) => { options.push({value: referral.id, text: t(referral.name) }) })
    return options
  })

  return {
    userReferrals,
    getUserReferrals,
    userReferralOptions
  }
}
