import { ref, computed } from '@vue/composition-api'
import httpClient from '../http/httpClient'
import store from '@/store'

export const useSubscriptionRepository = () => {
  const availableSubscriptions = ref([])
  const getAvailableSubscriptions = async () => {
    availableSubscriptions.value = store.getters['subscription/getAvailableSubscriptionList']
    if (availableSubscriptions.value.length <= 0) {
      await store.dispatch('subscription/getAvailableSubscriptionList')
      availableSubscriptions.value = store.getters['subscription/getAvailableSubscriptionList']
    }
  }
  const getSpecificAvailableSubscription = (id) => {
    getAvailableSubscriptions()
    return availableSubscriptions.value.find(subscription => subscription.id == id)
  }
  const availableSubscriptionForScholarshipOptions = computed(() => {
    let options = []
    availableSubscriptions.value.filter(subscription => subscription.packages.length === 1).forEach(subscription => options.push({value: subscription.id, text: subscription.title }))
    return options
  })

  const purchasedSubscriptions = ref([])
  const getPurchasedSubscriptions = async () => {
    await httpClient.fetchPurchasedSubscriptions()
      .then(response => {
        if(response.data.result) {
          purchasedSubscriptions.value = response.data.result
        }
      })
      .catch(error => {
        
      })
  }

  return {
    availableSubscriptions,
    getAvailableSubscriptions,
    getSpecificAvailableSubscription,
    availableSubscriptionForScholarshipOptions,
    purchasedSubscriptions,
    getPurchasedSubscriptions
  }
}