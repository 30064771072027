import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useMentorship = () => {
  const mentorships = ref([])
  const getMentorships = async () => {
    await httpClient.fetchMentorships()
      .then(response => {
        if(response.data.result) {
          mentorships.value = response.data.result
        }
      })
      .catch(error => {

      })
  }
  const stopMentorship = async (mentorship) => {
    await httpClient.stopMentorship(mentorship)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
    }

  const completeMentorship = async (mentorship) => {
    await httpClient.completeMentorship(mentorship)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(error => {
        return Promise.reject(error.response.data)
      })
    }

  return {
    mentorships,
    getMentorships,
    stopMentorship,
    completeMentorship
  }
}