import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()

export const useCountryRepository = () => {
  const countries = ref([])
  const getCountries = async () => {
    const resp = await httpClient.fetchCountries()
    countries.value = resp.data
  }
  const countryOptions = computed(() => {
    let options = [{ value: null, text: t('Choose') }]
    countries.value.map((country) => { options.push({value: country.id, text: t(country.name) }) })
    return options
  })
  const countryIdOfGreece = 67 // Greece
  
  return {
    countries,
    getCountries,
    countryOptions,
    countryIdOfGreece
  }
}
