import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()

const OTHER_INDUSTRY_ID = 37;

export const useIndustryRepository = () => {
  const industries = ref([])
  const getIndustries = async () => {
    const resp = await httpClient.fetchIndustries()
    industries.value = resp.data
  }
  const industryOptions = computed(() => {
    let options = []
    industries.value.map((domain) => { options.push({value: domain.id, text: domain.name }) })
    return options
  })
  
  return {
    OTHER_INDUSTRY_ID,
    industries,
    getIndustries,
    industryOptions
  }
}
