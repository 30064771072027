import { ref, computed } from '@vue/composition-api'
import { mentorFilters, mentorStatuses } from '@mentoring-platform/constants'
import { getCurrentInstance } from '@vue/composition-api'


export const useMentorFiltering = () => {

  const appliedFilters = ref([])
  let queryParam = ref("")
  const { proxy } = getCurrentInstance()
  const router = proxy.$router
  
  const applyFilters = (filters) => {
    appliedFilters.value = filters
  }

  const filteredMentors = computed(({ mentors }) => {
    let filteredMentors = mentors
    let onlyMentors = false
    let onlyCoaches = false
    let route = proxy.$route
    queryParam = ''
    appliedFilters.value.forEach((filter, key, arr) => {

      queryParam += filter.name
      if (filter.value !== undefined) {
        queryParam += '.' + filter.value
      }
      //note this is valueS instead of value
      if (filter.values !== undefined) { 
        queryParam += '..'
        filter.values.forEach((filterValue, key, arr) => {
          queryParam += filterValue
          if (!Object.is(arr.length - 1, key)) {
            queryParam += ','
          }
        })
      }
      if (!Object.is(arr.length - 1, key)) {
        queryParam += '|'
      }

      if (filter.name === mentorFilters.MENTOR_FILTER_SUPPORTS_GREEK) {
        filteredMentors = filteredMentors.filter(mentor => mentor.supported_languages.includes('gr'))
      }
      if (filter.name === mentorFilters.MENTOR_FILTER_SUPPORTS_ENGLISH) {
        filteredMentors = filteredMentors.filter(mentor => mentor.supported_languages.includes('en'))
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_ACTIVE) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.status == mentorStatuses.MENTOR_STATUS_ACTIVE)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_NOT_ACTIVE) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.status == mentorStatuses.MENTOR_STATUS_INACTIVE)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_MENTOR) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.is_mentor)
        onlyMentors = true
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_COACH) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.is_coach)
        onlyCoaches = true
      }
      /* public mentors list availability filters - start */
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_AVAILABLE) {
        if(onlyMentors) {
          filteredMentors = filteredMentors.filter((mentor) => mentor.is_available_for_mentoring)
        }
        else if(onlyCoaches) {
          filteredMentors = filteredMentors.filter((mentor) => mentor.is_available_for_coaching)
        }
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_NOT_AVAILABLE) {
        if(onlyMentors) {
          filteredMentors = filteredMentors.filter((mentor) => mentor.is_available_for_mentoring == false)
        }
        else if(onlyCoaches) {
          filteredMentors = filteredMentors.filter((mentor) => mentor.is_available_for_coaching == false)
        }
      }
      /* public mentors list availability filters - end */
      /* admin mentors list availability filters - start */
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_AVAILABLE_FOR_MENTORING) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.is_available_for_mentoring === true)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_NOT_AVAILABLE_FOR_MENTORING) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.is_available_for_mentoring === false)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_AVAILABLE_FOR_COACHING) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.is_coach && mentor.is_available_for_coaching === true)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_IS_NOT_AVAILABLE_FOR_COACHING) {
        filteredMentors = filteredMentors.filter((mentor) => mentor.is_coach && mentor.is_available_for_coaching === false)
      }
      /* admin mentors list availability filters - end */
      if(filter.name === mentorFilters.MENTOR_FILTER_COUNTRY) {
        const countryId = parseInt(filter.value)
        filteredMentors = filteredMentors.filter((mentor) => mentor.user.country_id == countryId)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_COUNTY) {
        const countyId = parseInt(filter.value)
        filteredMentors = filteredMentors.filter((mentor) => mentor.user.county_id == countyId)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_YEARS_OF_EXPERIENCE) {
        const minYearsOfExperience = parseInt(filter.value)
        filteredMentors = filteredMentors.filter((mentor) => mentor.user.years_of_experience >= minYearsOfExperience)
      }
      if(filter.name === mentorFilters.MENTOR_FILTER_INDUSTRY) {
        filteredMentors = filteredMentors.filter((mentor) => {
          return mentor.user.industries
            .filter(
              industry => filter.values.includes(industry.id)
            )
            .length > 0
        })
      }
      if(filter.name == mentorFilters.MENTOR_FILTER_FIELD_OF_SUPPORT) {
        filteredMentors = filteredMentors.filter((mentor) => {
          return mentor.fields_of_support
            .filter(
              fieldOfSupport => filter.values.includes(fieldOfSupport.id)
            )
            .length > 0
        })
        
      }
    })

    if (queryParam == mentorFilters.MENTOR_FILTER_IS_MENTOR || queryParam == '') {
      queryParam = undefined
    }
    if (route.query.f != queryParam) {
      if (queryParam !== undefined) {
        router.replace({ name: route.name, query: {f: queryParam} })
      } else {
        router.replace({ name: route.name })
      }
    }

    return filteredMentors
  })


  return {
    applyFilters,
    filteredMentors,
  }
}
