import { ref, computed } from '@vue/composition-api'
import store from '@/store'

export const useMentorRepository = () => {
    const mentors = ref([])
    const getMentors = async () => {
      mentors.value = store.getters['mentor/getList']
      if(mentors.value.length <= 0) {
        await store.dispatch('mentor/getList')
        mentors.value = store.getters['mentor/getList']
      }
    }
    const refreshMentors = async () => {
      await store.dispatch('mentor/getList')
      mentors.value = store.getters['mentor/getList']
    }
    const getSpecificMentor = async (id) => {
      await getMentors()
      return mentors.value.find(mentor => mentor.id == id)
    }

    const mentorApplications = ref([])
    const getMentorApplications = async () => {
      mentorApplications.value = store.getters['mentor/getApplicationsList']
      if(mentorApplications.value.length <= 0) {
        await store.dispatch('mentor/getApplicationsList')
        mentorApplications.value = store.getters['mentor/getApplicationsList']
      }
    }
    const refreshMentorApplications = async () => {
      await store.dispatch('mentor/getApplicationsList')
      mentorApplications.value = store.getters['mentor/getApplicationsList']
    }
    const getSpecificMentorApplication = async (id) => {
      await getMentorApplications()
      return mentorApplications.value.find(application => application.id == id)
    }

    return {
      mentors,
      getMentors,
      refreshMentors,
      getSpecificMentor,
      mentorApplications,
      getMentorApplications,
      refreshMentorApplications,
      getSpecificMentorApplication
    }
}
