import { ref, computed } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()

export const useFieldOfSupportRepository = () => {
  const fieldsOfSupport = ref([])
  const getFieldsOfSupport = async () => {
    const resp = await httpClient.fetchFieldsOfSupport()
    fieldsOfSupport.value = resp.data
  }
  const fieldOfSupportOptions = computed(() => {
    let options = []
    fieldsOfSupport.value.map((fieldOfSupport) => { options.push({value: fieldOfSupport.id, text: fieldOfSupport.name }) })
    return options
  })
  
  return {
    fieldsOfSupport,
    getFieldsOfSupport,
    fieldOfSupportOptions
  }
}
