import { ref } from '@vue/composition-api'
import httpClient from '@mentoring-platform/http/httpClient'

export const useMentorMessages = () => {
  const mentorMessages = ref([])
  const getMentorMessages = async (mentor) => {
    await httpClient.fetchMentorMessages(mentor)
      .then(response => {
        if(response.data.result) {
          mentorMessages.value = response.data.result
        }
      })
      .catch(error => {

      })
  }

  return {
    mentorMessages,
    getMentorMessages
  }
}